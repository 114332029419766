<template>
  <div class="cube" @click="getRandomCard" v-show="!fullRandomed">
    <div class="cube__text">
       <slot></slot>
    </div>
    <div class="cube__svg">
      <svg width="72" height="79" viewBox="0 0 72 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.0093 2.09981C37.0377 2.08038 38.054 2.3253 38.962 2.81138L65.0682 17.976C65.3233 18.1238 67.275 19.3642 67.275 19.3642C67.9632 19.7681 68.5335 20.3473 68.9284 21.0436C69.3233 21.7399 69.5288 22.5287 69.5242 23.3303L69.4701 55.04C69.4156 56.0703 69.1197 57.0731 68.6065 57.9665C68.0934 58.8598 67.3776 59.6184 66.5175 60.1804L39.0393 76.1227C38.1724 76.6272 37.1886 76.8928 36.1871 76.8928C35.1856 76.8928 34.2019 76.6272 33.3349 76.1227L5.89539 60.1804C5.03316 59.6203 4.31479 58.863 3.79896 57.9703C3.28312 57.0776 2.98443 56.0748 2.92728 55.0439L3.00458 23.1087C3.00506 22.3087 3.21409 21.5229 3.61082 20.8297C4.00754 20.1365 4.57807 19.5601 5.26544 19.1581C5.26544 19.1581 6.85771 18.1199 7.10505 17.9916L33.0451 2.82693C33.7722 2.42977 34.5768 2.19751 35.4026 2.14647C35.4476 2.14263 35.4928 2.14263 35.5378 2.14647C35.6886 2.14647 35.8393 2.14647 35.99 2.14647L36.0093 2.09981ZM36.0093 8.74341e-05C35.8161 8.74341e-05 35.6229 8.74341e-05 35.4258 0.0195293L35.2364 0.0350828C34.1047 0.111924 33.004 0.439916 32.0132 0.99551L6.07317 16.1602C5.8181 16.3118 4.69733 17.0389 4.18332 17.3694C3.19176 17.9598 2.36953 18.7991 1.79709 19.8051C1.22465 20.8111 0.921607 21.9495 0.917627 23.1087L0.840332 55.0361C0.892333 56.4361 1.28315 57.8022 1.97886 59.0158C2.67458 60.2294 3.65416 61.2538 4.83259 62.0001L32.2915 77.9425C33.476 78.6301 34.8195 78.9921 36.1871 78.9921C37.5547 78.9921 38.8982 78.6301 40.0827 77.9425L67.5609 61.9807C68.7396 61.2354 69.7201 60.2126 70.4177 59.0006C71.1153 57.7886 71.509 56.4239 71.5648 55.0244L71.6112 23.3303C71.6102 22.1675 71.3078 21.025 70.7337 20.0157C70.1596 19.0065 69.3338 18.1654 68.3378 17.5755C67.8353 17.2567 66.3551 16.3196 66.0923 16.164L39.9861 0.999398C38.7673 0.329438 37.3981 -0.0146398 36.0093 8.74341e-05V8.74341e-05Z" fill="#959BAC"/>
<path d="M67.2556 19.3641C67.2556 19.3641 65.3232 18.1237 65.0488 17.9759L38.9426 2.8113C37.8971 2.25667 36.7155 2.01512 35.5378 2.11528C35.4928 2.11144 35.4475 2.11144 35.4025 2.11528C34.5768 2.16632 33.7721 2.39858 33.045 2.79574L7.10501 17.9604C6.85767 18.1043 5.2654 19.1269 5.2654 19.1269C4.57349 19.5315 4.00003 20.1128 3.60304 20.812C3.20605 21.5112 2.99959 22.3034 3.00454 23.1086L2.92725 55.0438C2.98036 56.0727 3.27404 57.0744 3.78441 57.9676C4.29478 58.8608 5.00743 59.6203 5.86444 60.1842L33.3233 76.1265C34.1902 76.631 35.174 76.8966 36.1755 76.8966C37.177 76.8966 38.1607 76.631 39.0276 76.1265L66.5058 60.1842C67.3659 59.6222 68.0818 58.8636 68.5949 57.9703C69.1081 57.0769 69.404 56.0741 69.4585 55.0438L69.5242 23.3302C69.5268 22.5267 69.3184 21.7367 68.92 21.0402C68.5217 20.3437 67.9475 19.7655 67.2556 19.3641V19.3641Z" fill="white"/>
<path d="M66.4247 23.1319L40.3184 38.2966C38.6837 39.2453 37.3619 41.5434 37.3697 43.4331L37.4547 73.6224C37.4547 75.5044 38.788 76.2665 40.4228 75.3177L66.529 60.1531C68.1638 59.2043 69.4855 56.9024 69.4778 55.0166L69.3928 24.8311C69.3928 22.9414 68.0594 22.1832 66.4247 23.1319" fill="#C2C2D5"/>
<path d="M43.6846 40.9679C41.8836 42.0138 40.4344 44.5413 40.4382 46.6177C40.4421 48.6941 41.9068 49.534 43.7039 48.4919C45.501 47.4498 46.9503 44.9185 46.9464 42.8382C46.9426 40.7579 45.4817 39.9258 43.6846 40.9679" fill="#2657BE"/>
<path d="M63.0855 29.6994C61.2884 30.7415 59.8392 33.2689 59.843 35.3453C59.8469 37.4217 61.3116 38.2655 63.1049 37.2195C64.8981 36.1735 66.3551 33.6461 66.3512 31.5658C66.3474 29.4855 64.8826 28.6534 63.0855 29.6994" fill="#2657BE"/>
<path d="M43.7387 61.2302C41.9416 62.2761 40.4924 64.8036 40.4962 66.88C40.5001 68.9564 41.9648 69.7963 43.7619 68.7542C45.559 67.7121 47.0083 65.1769 47.0044 63.1005C47.0006 61.0241 45.5358 60.1881 43.7387 61.2302Z" fill="#2657BE"/>
<path d="M63.1435 49.9617C61.3464 51.0038 59.8933 53.5312 59.901 55.6076C59.9088 57.684 61.3658 58.5277 63.1629 57.4818C64.96 56.4358 66.4131 53.9045 66.4092 51.8281C66.4054 49.7517 64.9406 48.9157 63.1435 49.9617Z" fill="#2657BE"/>
<path d="M65.0489 17.9759L38.9427 2.81128C38.029 2.34441 37.0187 2.10107 35.9939 2.10107C34.9691 2.10107 33.9588 2.34441 33.0451 2.81128L7.10507 17.9759C5.48189 18.9247 5.49348 20.4645 7.12826 21.4132L33.2345 36.5779C34.1479 37.0457 35.1583 37.2895 36.1833 37.2895C37.2082 37.2895 38.2186 37.0457 39.1321 36.5779L65.0721 21.4132C66.6914 20.4645 66.6837 18.9247 65.0489 17.9759" fill="#F2F1F8"/>
<path d="M39.3214 17.8048C37.5204 16.7628 34.618 16.7628 32.8364 17.8048C31.0547 18.8469 31.0586 20.5267 32.8364 21.5843C34.6141 22.642 37.5359 22.6264 39.3214 21.5843C41.1069 20.5423 41.0991 18.8625 39.302 17.8048" fill="#FF7900"/>
<path d="M32.106 38.3782L5.99588 23.2136C4.3611 22.2648 3.03164 23.0269 3.02777 24.9167L2.94275 55.0982C2.99683 56.1277 3.29218 57.1297 3.80466 58.0224C4.31715 58.9151 5.03224 59.6732 5.89153 60.2347L32.0016 75.3994C33.6325 76.3481 34.962 75.586 34.9697 73.6963L35.0547 43.5109C34.9988 42.4823 34.7027 41.4816 34.1904 40.5898C33.678 39.698 32.9639 38.9403 32.106 38.3782" fill="#D7DCEB"/>
<path d="M9.34275 29.7771C7.54565 28.7312 6.08479 29.5749 6.07706 31.6513C6.06933 33.7277 7.52246 36.259 9.32343 37.305C11.1244 38.351 12.5775 37.5072 12.5853 35.4308C12.593 33.3544 11.1321 30.8231 9.34275 29.7771Z" fill="#2657BE"/>
<path d="M28.7359 41.0495C26.9426 40.0035 25.4818 40.8434 25.474 42.9237C25.4663 45.004 26.9194 47.5314 28.7165 48.5735C30.5136 49.6156 31.9745 48.7796 31.9822 46.7032C31.99 44.6268 30.5368 42.0916 28.7359 41.0495Z" fill="#2657BE"/>
<path d="M9.27697 50.0394C7.47987 48.9973 6.01901 49.8372 6.01514 51.9136C6.01128 53.99 7.46055 56.5175 9.25765 57.5634C11.0547 58.6094 12.5156 57.7695 12.5195 55.6931C12.5233 53.6167 11.0741 51.0854 9.27697 50.0394Z" fill="#2657BE"/>
<path d="M28.6664 61.3118C26.8693 60.2658 25.4084 61.1057 25.4007 63.186C25.3929 65.2663 26.8615 67.7898 28.6664 68.8241C30.4712 69.8584 31.9282 69.0341 31.932 66.9577C31.9359 64.8813 30.4789 62.3539 28.6664 61.3118Z" fill="#2657BE"/>
<path d="M19.0045 45.5484C17.2074 44.5024 15.7465 45.3384 15.7388 47.4148C15.7311 49.4912 17.1842 52.0225 18.9852 53.0685C20.7861 54.1144 22.2431 53.2746 22.247 51.1943C22.2509 49.114 20.8055 46.5905 19.0045 45.5484Z" fill="#2657BE"/>
</svg>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';



export default {
  props: {
    
  },
  data() {
    return {
      randomed: []
    }
  },
  methods: {
    ...mapMutations({
      setOverlayed: 'setOverlayed',
      pushQueue: 'pushQueue',
      setIterator: 'setIterator',
      setRandomed: 'setRandomed',
      setLoading: 'setLoading',
      setCubeRandom: 'setCubeRandom'
    }),
    getRandomCard() {
      this.setOverlayed(true);
      this.limitBodyOverflow();

      let cubeRandom = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
      this.setCubeRandom(cubeRandom);

      this.setLoading(true);
      let range = [...this.$store.getters.flatBlocks.keys()];
      let stored = this.$store.state.randomed;
      if (range.length == stored.length) {
        return false;
      }
      let rand = null;  //an integer

      while(rand === null || this.$store.state.randomed.includes(rand)){
        rand = Math.round(Math.random() * (range.length - 1));
      }

      setTimeout(() => {
        this.setLoading(false);
        this.setRandomed(rand);
        this.openCard(this.$store.getters.flatBlocks[rand]);
        this.setIterator(1);
      }, 3500);
      

      
    },
    openCard(card) {
      this.setOverlayed(true);
      this.limitBodyOverflow();
      this.pushQueue(card);
    },
    limitBodyOverflow() {
      document.body.classList.add('overflowed');
    }
  },
  computed: {
    fullRandomed() {
      return this.$store.state.randomed.length == this.$store.getters.flatBlocks.length;
    }
  },
  mounted() {
  }
}
</script>

<style>
  .cube {
    position: fixed;
    right: 64px;
    bottom: 51px;
    width: 130px;
    height: 130px;
    background: linear-gradient(180deg, #FF9F63 0%, #FF7C08 100%), #FF7900;
    border-radius: 130px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    transition: all 100ms ease-in-out;
    padding-right: 30px;
    justify-content: flex-end;
    z-index: 5;
    box-shadow: -6px 12px 18px 0px rgba(255, 160, 102, 0.25), -3px 6px 12px 0px rgba(255, 121, 0, 0.25);
  }

  .cube__text {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;

    /* white */

    color: #FFFFFF;
  }

  .cube__svg {
    margin-left: 25px;
    transition: all 100ms ease-in-out;
  }

  .cube:hover {
    width: 287px;
    box-shadow: 0px 10px 10px rgba(255, 162, 109, 0.35);
  }

  .cube:hover .cube__svg {
    transform: rotate(26deg);
  }
</style>