<template>
  <header class="header">
    <div class="header__inner container">
      <div class="logo" @click="home">Digital-витрина</div>
      <nav class="nav-filter">
        <ul>
          <li v-for="tag in $store.state.tags" :key="tag.code" :class="{ selected: tag.code == $store.state.selectedTag}" @click="setSelected(tag.code)"><a href="javascript:;">{{ tag.title }}</a></li>
        </ul>
      </nav>
    </div>
    
  </header>
  
</template>

<script>
import {mapMutations} from 'vuex';

export default {
    methods: {
        ...mapMutations({
            setSelectedTag: 'setSelectedTag',
        }),
        setSelected(tagCode) {
          let commitState = tagCode;
          if (commitState == this.$store.state.selectedTag) {
            commitState = false;
          }

          this.setSelectedTag(commitState);
        },
        home() {
          this.setSelectedTag(false);
        }
    },
}
</script>

<style>
  .header {
    /* azure */
    background: #2355CD;
    box-shadow: 0px 4px 30px rgba(0, 87, 182, 0.3);
    height: 97px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 4;
  }

  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .logo {
    font-weight: 700;
    font-size: 35px;
    line-height: 44px;
    /* identical to box height */

    text-transform: uppercase;

    /* white */

    color: #FFFFFF;
    cursor: pointer;
  }

  .nav-filter ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .nav-filter ul li {
    margin-left: 10px;
  }

  .nav-filter ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    padding: 6px 16px 8px;
    border: 2px solid #FFFFFF;
    border-radius: 27px;
    transition: all 100ms ease-in-out;
  }

  .nav-filter ul li a:hover, .nav-filter ul li.selected a  {
    background: #FFFFFF;
    /* hover for menu button */

    box-shadow: 0px 5px 20px 5px #8EA7E3;
    border-radius: 27px;
    color: #2355D7;
  }

</style>