import { createApp } from 'vue'
import App from './components/App'
import store from './store'
import LottieAnimation from "lottie-web-vue";

import "normalize.css"
import "./assets/fonts/Cera/cera.css"
import "./assets/css/main.css"


createApp(App).use(store).use(LottieAnimation).mount('#app')
