import axios from 'axios';
import { createStore } from 'vuex'

export default createStore({
  state: () => ({
    blocks: [],
    tags: [],
    selectedTag: false,
    loading: false,
    overlayed: false,
    queue: [],
    currentCard: false,
    iterator: -1,
    randomed: [],
    cubeRandom: 1
  }),
  getters: {
    mappedTags(state) {
      let mappedObject = {};
      state.tags.forEach((obj) => {
        mappedObject[obj.code] = obj.title;
      });

      return mappedObject;
    },
    filteredBlocks(state, getters) {
      if (!state.selectedTag) {
        return state.blocks;
      }
      let filteredBlocks = getters.flatBlocks.filter(function (el)
        {
          return el.tag == state.selectedTag;
        }
      );

      filteredBlocks.sort((a, b) => {
        let order = 0;

        if (b.size > a.size) {
          order = -1;
        } else if (b.size < a.size) {
          order = 1;
        }

        return order;
      });

      return filteredBlocks;
    },
    flatBlocks(state) {
      let flatBlocks = [];
      state.blocks.forEach((block) => {
        if (block.type == 'card') {
          flatBlocks.push(block);
        } else {
          block.cards.forEach((card) => {
            flatBlocks.push(card);
          });
        }
      }); 

      return flatBlocks;
    }
  },
  mutations: {
    setBlocks(state, blocks) {
      state.blocks = blocks;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setSelectedTag(state, selectedTag) {
      state.selectedTag = selectedTag;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setOverlayed(state, overlayed) {
      state.overlayed = overlayed;
    },
    setCurrentCard(state, card) {
      state.currentCard = card;
    },
    setIterator(state, increment) {
      if (increment === false) {
        state.iterator = -1;
      } else {
        state.iterator += increment;
      }
    },
    pushQueue(state, item) {
      state.queue.push(item);
    },
    popQueue(state) {
      state.queue = [];
    },
    setRandomed(state, randomed) {
      if (randomed !== false) {
        state.randomed.push(randomed);
      } else {
        state.randomed = [];
      }
    },
    setCubeRandom(state, randomNumber) {
      state.cubeRandom = randomNumber;
    }
  },
  actions: {
    async fetchCards({ commit}) {
      try {
        commit('setLoading', true);
        const response = await axios.get('/data/data.json', {});
        commit('setTags', response.data.tags);
        commit('setBlocks', response.data.blocks);
        commit('setLoading', false);
      } catch (e) {
        commit('setLoading', false);
        console.log(e);
      }
    }
  },
  modules: {
  }
})
