<template>
  <div class="modal-card">
    <a href="javascript:;" class="modal-card__close" @click="closeCard"></a>
    <div class="modal-flex">
      <div class="modal-left">
          <h3 class="modal-card__title" v-html="card.title"></h3>
          <div class="modal-card__previewText"  v-html="card.detailText"></div>

          <footer class="modal-card__footer">
            <div class="modal-tabs">
                  <a href="javascript:;" 
                    @click="tab = 'desktop'" 
                    :class="[{'modal-tabs__btn--selected': tab == 'desktop'}]" 
                    class="modal-tabs__btn modal-tabs__btn--desktop" v-if="card.hasDesktop">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 2H3C1.9 2 1 2.9 1 4V16C1 17.1 1.9 18 3 18H10L8 21V22H16V21L14 18H21C22.1 18 23 17.1 23 16V4C23 2.9 22.1 2 21 2ZM21 14H3V4H21V14Z" fill="#001250"/>
                      </svg>
                  десктоп</a>
                  <a href="javascript:;" @click="tab = 'mobile'"
                    :class="[{'modal-tabs__btn--selected': tab == 'mobile'}]"  
                    class="modal-tabs__btn modal-tabs__btn--desktop" 
                    v-if="card.hasMobile">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M7 4H16V18H7V4Z" fill="#001250"/>
                      <path d="M15.5 1H7.5C6.12 1 5 2.12 5 3.5V20.5C5 21.88 6.12 23 7.5 23H15.5C16.88 23 18 21.88 18 20.5V3.5C18 2.12 16.88 1 15.5 1ZM11.5 22C10.67 22 10 21.33 10 20.5C10 19.67 10.67 19 11.5 19C12.33 19 13 19.67 13 20.5C13 21.33 12.33 22 11.5 22ZM16 18H7V4H16V18Z" fill="#001250"/>
                      </svg>
                  смартфон</a>
                  <div class="modal-tabs__tab modal-tabs__tab--desktop" v-show="tab == 'desktop'">
                    <a :href="card.link" :disabled="!card.link || card.soon" :class="{ disabled: !card.link || card.soon }" target="_blank" class="desktop-link">
                      <span v-if="card.button_text">{{ card.button_text }}</span>
                      <span v-else-if="card.soon">Скоро</span>
                      <span v-else>Открыть проект</span>
                    </a>
                  </div>
                  <div class="modal-tabs__tab modal-tabs__tab--mobile" v-show="tab == 'mobile'" :class="{ soonMobile: !card.qr || card.soon}">
                    <div class="mobile-qr-flex" v-if="card.qr && !card.soon">
                      <img class="qr-image" :src="card.qr" width="127" alt="">
                      <div class="qr-disclaimer">Чтобы открыть {{ card.specialType && card.specialType == 'telegram' ? 'стикерпак' : 'проект'}}, отсканируйте QR-код камерой смартфона</div>
                    </div>
                    <a v-else :disabled="!card.link || card.soon" :class="{ disabled: !card.link || card.soon }" target="_blank" class="desktop-link">
                      <span v-if="card.button_text">{{ card.button_text }}</span>
                      <span v-else-if="card.soon">Скоро</span>
                      <span v-else>Открыть проект</span>
                    </a>
                  </div>
            </div>
          </footer>
          
      </div>
      <div class="modal-right"><img :src="card.detailPic" width="267" alt=""></div>
    </div>
  </div>
  
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  components: {
    
  },
  data() {
    return {
      tab: 'desktop'
    }
  },
  methods: {
    ...mapMutations({
      setOverlayed: 'setOverlayed',
      setIterator: 'setIterator',
      popQueue: 'popQueue',
      setRandomed: 'setRandomed'
    }),
    closeCard() {
      this.setOverlayed(false);
      this.resetBodyOverflow();
      this.setIterator(false);
      this.popQueue();
      this.setRandomed(false);
    },
    resetBodyOverflow() {
      document.body.classList.remove('overflowed');
    }
  },
  mounted() {
    if (this.card.hasDesktop) {
      this.tab = 'desktop';
    } else {
      this.tab = 'mobile';
    }
  }
}
</script>

<style>
  .modal-card {
    width: 800px;
    height: 550px;
    background: #FFFFFF;
    border-radius: 40px;
    position: relative;
    z-index: 7;
    padding: 42px 66px 55px;
    position: absolute;
  }

  .modal-flex {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  .modal-card__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 110.7%;
    color: #2355D7;
    margin-bottom: 12px;
    margin-top: 0;
  }

  .modal-card__previewText {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;

    /* dark blue */

    color: #001250
  }
  
  .modal-left {
    width: 330px;
    display: flex;
    flex-direction: column;
  }

  .modal-right {
    display: flex;
    align-items: flex-end;
  }

  .modal-card__footer {
    margin-top: auto;
    height: 190px;
  }

  .modal-tabs__btn {
    display: inline-flex;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;

    /* dark blue */

    color: #001250;
    align-items: center;
    text-decoration: none;
    padding-bottom: 15px;
    border: none;
    border-bottom: 2px solid transparent;
  }

  .modal-tabs__btn--selected, .modal-tabs__btn:hover {
    border-bottom: 2px solid #FF7900;
  }

  .modal-tabs__btn--desktop {
    margin-right: 54px;
  }

  .modal-tabs__btn svg {
    margin-right: 9px;
  }

  .modal-tabs__tab--desktop {
  }

  .desktop-link {
    /* Buttons/button */
    background: linear-gradient(90deg, #4478FF -12.02%, #2355D7 53.12%);
    box-shadow: -6px 12px 24px 0px rgba(35, 85, 215, 0.50), -12px 24px 30px 0px rgba(115, 176, 255, 0.20);
    border-radius: 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;

    /* white */

    color: #FFFFFF;
    text-decoration: none;
        height: 65px;
    display: inline-flex;
    align-items: center;
    padding: 0 77px;
    margin-top: 44px;

  }

  .desktop-link:hover {
    color: #ffffff;
    /* orange shadow */
    background: linear-gradient(319deg, #FF7900 8.18%, #FFA066 68.89%), linear-gradient(90deg, #4478FF -12.02%, #2355D7 53.12%);
    box-shadow: -12px 24px 36px 0px rgba(255, 160, 102, 0.25), -6px 12px 24px 0px rgba(255, 121, 0, 0.25);

  }

  .desktop-link.disabled {
    cursor: default;
    pointer-events: none;
    touch-action: none;
    opacity: 0.3;
    background: linear-gradient(90deg, #4478FF -12.02%, #2355D7 53.12%);
    box-shadow: -6px 12px 24px 0px rgba(35, 85, 215, 0.50), -12px 24px 30px 0px rgba(115, 176, 255, 0.20);
  }

  .modal-card__close {
    position: absolute;
    top: 45px;
    right: 48px;
    width: 31px;
    height: 31px;
    background: url('~@/assets/icons/cross-blue.svg') center center no-repeat;
  }

  .modal-card__close:hover {
    background: url('~@/assets/icons/cross-blue-hl.svg') center center no-repeat;
  }

  .mobile-qr-flex {
    display: flex;
    align-items: flex-start;
  }

  .qr-disclaimer {
    font-weight: 400;
font-size: 15px;
line-height: 19px;

/* dark blue */

color: #001250;
  margin-left: 35px;
  }

  .modal-tabs__tab--mobile {
    padding-top: 22px;
  }

  .modal-tabs__tab--mobile.soonMobile {
    padding-top: 0;
  }

  
</style>