<template>
  <div class="cards-slider">
    <div class="cards-slider__bg"></div>
    <div class="cards-slider__inner">
      <div class="slider-disclaimer" v-html="block.dislaimer"></div>
      <div class="inner-grid" v-if="block.cards.length <= 3">
        <single-card v-for="(card, index) in block.cards" :card="card" :key="block.specialType + '_' + index"></single-card>
      </div>
      <div class="actual-slider" v-else>
        <carousel :items-to-show="3">
          <slide v-for="(card, index) in block.cards" :key="block.specialType + '_' + index">
            <single-card :card="card"></single-card>
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>
    
  </div>
</template>

<script>
//import TelegramCard from '@/components/TelegramCard.vue';
import SingleCard from '@/components/SingleCard.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  components: {
    //TelegramCard,
    SingleCard,
    Carousel,
    Slide,
    Navigation,
  },
   props: {
    block: {
      type: Object,
      required: true
    }
  },
  methods: {
   
  },
  mounted() {
  }
}
</script>

<style>
  .cards-slider {
    position: relative;
    
    padding: 49px 0 90px;
  }

  .cards-slider__bg {
    background:  linear-gradient(90deg, #D1E5FF 66.86%, #73B0FF 132.21%);
    position: absolute;
    width: 100vw;
    height: 100%;
    left: calc((100vw - 1180px - 17px)/2*-1);
    z-index: 1;
    top: 0;
    border-top: 2px solid #73B0FF;
    border-bottom: 2px solid #73B0FF;
  }

  @media all and (max-width: 1281px) {
    .cards-slider__bg {
      left: calc((100vw - 1163px - 17px)/2*-1);
    }
  }

  .slider-disclaimer {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;

    /* dark blue */

    color: #001250;
  }

  .cards-slider__inner {
    position: relative;
    z-index: 2;
  }

  .inner-grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 20px;
  }

  .carousel__viewport {
    overflow: visible !important;
  }

  li.carousel__slide {
    transition: all 0.3s linear;
  }

  li.carousel__slide[aria-hidden="true"] {
    opacity: 0;
  }

  .actual-slider {
    margin-top: 50px;
    width: calc(100% + 20px);
  }

  .carousel__next, .carousel__prev {
    width: 50px !important;
    height: 50px !important;
    background: #ffffff;
    border-radius: 50%;
    border: 4px solid #D1E5FF;
  }

  .carousel__next svg, .carousel__prev svg {
    display: none !important;
  }

  .carousel__next {
    right: -25px !important;
    background: #ffffff url("~@/assets/icons/arrow-r.svg") center center no-repeat;
  }

  .carousel__prev {
    left: -25px !important;
    background: #ffffff url("~@/assets/icons/arrow-l.svg") center center no-repeat;
  }

  .carousel__prev--disabled, .carousel__next--disabled {
    display: none !important;
  }
</style>