<template>
  <div class="content container">
    <div class="content__disclaimer">Газпромбанк: у нас тут <br> своя атмосфера</div>
    <transition-group name="item" class="grid" tag="div">
        <div class="grid-item item" :class="block.size" v-for="(block, index) in $store.getters.filteredBlocks" v-show="!$store.state.selectedTag || block.tag == $store.state.selectedTag " :key="'block' + index">
            <content-block :block="block"></content-block>
        </div>
    </transition-group>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import ContentBlock from '@/components/ContentBlock.vue';

export default {
  components: {
    ContentBlock
  },
  methods: {
    ...mapActions({
      fetchCards: 'fetchCards'
    })
  },
  mounted() {
    this.fetchCards();
  }
}
</script>

<style>
  .content {
    
  }
  .grid {
    padding-top: 65px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 20px;
    grid-row-gap: 80px;
    margin-bottom: 80px;
  }

  .grid-item {

  }

  .grid-item.full {
    grid-column: 1 / span 3;
  }

  .content__disclaimer {
    color: #2355D7;
    font-weight: 700;
    margin-top: 30px;
    font-size: 44px;
    line-height: 110%;
  }

.item-move {
  transition: all 300ms ease-in-out 50ms;
}
.item-enter-active {
  transition: all 100ms ease-out;
}
/*.item-leave-active {
  transition: all 00ms ease-in;
  position: absolute;
  z-index: 0;
}*/
.item-enter, .item-leave-to {
  opacity: 0;
}
.item-enter {
  transform: scale(0.9);
}

</style>