<template>
  <single-card v-if="block.type == 'card'" :card="block"></single-card>
  <card-slider v-else :block="block"></card-slider>
</template>

<script>
import SingleCard from '@/components/SingleCard.vue';
import CardSlider from '@/components/CardSlider.vue';

export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  components: {
    SingleCard,
    CardSlider
  },
  methods: {
    
  },
  mounted() {
    
  }
}
</script>

<style>


</style>