<template>
  <div class="app-wrapper">
    <main-header></main-header>
    <cards-grid></cards-grid>
    <random-cube v-if="!$store.state.overlayed">Удивите <br>меня!</random-cube>
    <footer class="main-footer">
        Лучшее создается вместе!
    </footer>
    <transition name="overlay">
        <div class="overlay"  v-if="$store.state.overlayed">
            <div class="overlay__bg" @click="closeCard"></div>
            <loading-video v-show="$store.state.loading" :cube="$store.state.cubeRandom"></loading-video>   
            <!--<a href="javascript:;" class="overlay__close" @click="closeCard"></a>-->

                
                    <div class="modals-container">
                        <div class="modals-wrapper">
                            <transition-group v-if="!$store.state.loading" class="modals-lister" ref="modalsLister" name="modalcard" tag="div">    
                                <modal-card :card="card" v-for="(card, index) in $store.state.queue" v-show="index == $store.state.iterator || $store.state.iterator < 0" :key="'modal_' + index" ></modal-card>
                            </transition-group>
                            <random-cube v-if="$store.state.overlayed">следующий <br> случайный</random-cube>
                            <a href="javascript:;" class="slider-arrow slider-arrow--left" @click="prevCard" v-show="hasPrev">
                                <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5875 2.8375L12.3625 0.625L0 13L12.375 25.375L14.5875 23.1625L4.425 13L14.5875 2.8375Z" fill="white"/>
</svg>
                            </a>
                            <a href="javascript:;" class="slider-arrow slider-arrow--right" @click="nextCard" v-show="hasNext">
                                <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-9.65145e-05 23.1625L2.2249 25.375L14.5874 13L2.2124 0.625001L-9.82914e-05 2.8375L10.1624 13L-9.65145e-05 23.1625Z" fill="white"/>
</svg>
                            </a>
                        </div>
                        
                    </div>
        </div>
    </transition>
  </div>
</template>

<script>
import MainHeader from './MainHeader.vue';
import CardsGrid from './CardsGrid.vue';
import {mapMutations} from 'vuex';
import RandomCube from './RandomCube.vue';
import ModalCard from '@/components/ModalCard.vue';
import LoadingVideo from '@/components/LoadingVideo.vue';

export default {
    components: {
        MainHeader,
        CardsGrid,
        ModalCard,
        RandomCube,
        LoadingVideo
    },
    methods: {
        ...mapMutations({
            setOverlayed: 'setOverlayed',
            popQueue: 'popQueue',
            setIterator: 'setIterator',
            setRandomed: 'setRandomed'
        }),
        closeCard() {
            this.setOverlayed(false);
            this.resetBodyOverflow();
            this.setIterator(false);
            this.popQueue();
            this.setRandomed(false);
        },
        openCard() {
            
        },
        resetBodyOverflow() {
            document.body.classList.remove('overflowed');
        },
        nextCard() {
            this.setIterator(1);
        },
        prevCard() {
            this.setIterator(-1);
        }
    },
    computed: {
        hasNext() {
            let randomedCount =  this.$store.state.queue.length;
            let iterator = this.$store.state.iterator;

            return (randomedCount > 1) && (iterator + 1) < randomedCount;
        },
        hasPrev() {
            let randomedCount =  this.$store.state.queue.length;
            let iterator = this.$store.state.iterator;
            console.log(iterator);
            return (randomedCount > 1) & (iterator > 0);
        }
    }
}
</script>

<style>
    .app-wrapper {
        background: linear-gradient(180deg, #73B0FF -5.28%, #D1E5FF 4.15%, #FFF 21.71%), #FFF;
        display: flex;
        flex-direction: column;
    }

    .main-footer {
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        color: #0057B6;
        padding-top: 12px;
        text-align: center;
        height: 87px;
        display: flex;
        margin-top: auto;
        justify-content: center;
    }

    .card {
        cursor: pointer;
        top: 0;
        transition: all 300ms ease-in-out;
    }

    .card:hover {
        top: -10px;
    }

    .overlay, .overlay__bg {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .overlay__bg {
         background: rgba(35, 87, 216, 0.60);
         z-index: 5;
    }

    .overlay__close {
        position: absolute;
        top: 42px;
        right: 74px;
        z-index: 6;
        height: 31px;
        width: 31px;
        background: url('~@/assets/icons/cross-white.svg') center center no-repeat;
    }

    .overlay__close:hover {
        background: url('~@/assets/icons/cross-white-hl.svg') center center no-repeat;
    }

    .modals-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .modals-wrapper {
        position: relative;
    }

    .modals-wrapper .cube {
    position: absolute;
    bottom: 0;
    right: -190px;
    overflow: visible;
    justify-content: center;
    padding: 0;
  }

  .modals-wrapper .cube:hover {
    width: 130px;
  }

  .modals-wrapper .cube__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;

    /* white */

    color: #FFFFFF;
    position: absolute;
    top: -60px;
    display: none;
  }

  .modals-wrapper .cube__svg {
    margin: 0;
  }

  .modals-wrapper .cube:hover .cube__text {
    display: block;
  }

  .slider-arrow {
    position: absolute;
    width: 68px;
    height: 68px;
    background: #FF7900;
    border-radius: 130px;
    display: flex;
    align-items: center;
    z-index: 5;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .slider-arrow:hover {
    box-shadow: 0px 10px 10px rgb(255 162 109 / 35%);
  }

  .slider-arrow.slider-arrow--left {
    left: -94px;
    padding-left: 24px;
  }

  .slider-arrow.slider-arrow--right {
    right: -94px;
    padding-left: 29px;
  }

  .modals-lister {
    position: relative;
    width: 800px;
    height: 550px;
  }

  /* transitions animate */

  /*.modalcard-enter-active,
    .modalcard-leave-active {
        transition: all 0.5s ease;
    }
    .modalcard-enter-from,
    .modalcard-leave-to {
        opacity: 0;
        transform: translateX(-400px);
    }*/

    .carousel {
      text-align: left;
    }
</style>