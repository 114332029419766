<template>
  <div class="loader">
    <div class="loader__text">Подбираем проект...</div>
    <div class="loader__cube">
      <img width="200" height="200" :src="'/data/loader/kub_' + cube + '.gif'" />
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';



export default {
  props: {
    cube: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      randomed: []
    }
  },
  methods: {
    ...mapMutations({
      setOverlayed: 'setOverlayed',
      pushQueue: 'pushQueue',
      setIterator: 'setIterator',
      setRandomed: 'setRandomed'
    }),
    getRandomVideo() {
     
    }
  },
  computed: {
  },
  mounted() {
  }
}
</script>

<style>
  .loader {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 6;
  }

  .loader__text {
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF
  }
</style>