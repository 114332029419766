<template>
  <div class="card" :class="[ card.theme, card.size, { soon: card.soon }]" @click="openCard">
    <div class="card-soon" v-if="card.soon && card.soonText">
      {{ card.soonText }}
    </div>
    <div class="card__inner" :style="{ 'background-image': card.previewPic ? 'url(' + card.previewPic + ')' : 'url(/data/img/cards/telegram_bg.jpg)' }">
      <header class="card-header">
        <div class="card__tag">{{ $store.getters.mappedTags[card.tag] }}</div>
        <div class="desktop-icon card__icon" v-if="card.hasDesktop && !card.specialType">
          <svg v-if="card.theme == 'light'" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 2.5H3C1.9 2.5 1 3.4 1 4.5V16.5C1 17.6 1.9 18.5 3 18.5H10L8 21.5V22.5H16V21.5L14 18.5H21C22.1 18.5 23 17.6 23 16.5V4.5C23 3.4 22.1 2.5 21 2.5ZM21 14.5H3V4.5H21V14.5Z" fill="#001250"/>
          </svg>
          <svg v-if="card.theme == 'dark'" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 2.5H3C1.9 2.5 1 3.4 1 4.5V16.5C1 17.6 1.9 18.5 3 18.5H10L8 21.5V22.5H16V21.5L14 18.5H21C22.1 18.5 23 17.6 23 16.5V4.5C23 3.4 22.1 2.5 21 2.5ZM21 14.5H3V4.5H21V14.5Z" fill="white"/>
          </svg>

        </div>
        <div class="mobile-icon card__icon" v-if="card.hasMobile && !card.specialType">
          <svg v-if="card.theme == 'light'" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M7 4.5H16V18.5H7V4.5Z" fill="#001250"/>
            <path d="M15.5 1.5H7.5C6.12 1.5 5 2.62 5 4V21C5 22.38 6.12 23.5 7.5 23.5H15.5C16.88 23.5 18 22.38 18 21V4C18 2.62 16.88 1.5 15.5 1.5ZM11.5 22.5C10.67 22.5 10 21.83 10 21C10 20.17 10.67 19.5 11.5 19.5C12.33 19.5 13 20.17 13 21C13 21.83 12.33 22.5 11.5 22.5ZM16 18.5H7V4.5H16V18.5Z" fill="#001250"/>
          </svg>
          <svg v-if="card.theme == 'dark'" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M7 4.5H16V18.5H7V4.5Z" fill="white"/>
            <path d="M15.5 1.5H7.5C6.12 1.5 5 2.62 5 4V21C5 22.38 6.12 23.5 7.5 23.5H15.5C16.88 23.5 18 22.38 18 21V4C18 2.62 16.88 1.5 15.5 1.5ZM11.5 22.5C10.67 22.5 10 21.83 10 21C10 20.17 10.67 19.5 11.5 19.5C12.33 19.5 13 20.17 13 21C13 21.83 12.33 22.5 11.5 22.5ZM16 18.5H7V4.5H16V18.5Z" fill="white"/>
          </svg>
        </div>

        <div class="telegram-icon card__icon" v-if="card.specialType && card.specialType == 'telegram'">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.9644 3.90539L18.1398 19.0207C17.9599 19.7325 17.1274 20.0993 16.4449 19.7612L11.6077 17.3668L9.32049 20.955C8.69814 21.933 7.12331 21.5087 7.12331 20.3652V16.3671C7.12331 16.0579 7.25823 15.7631 7.49074 15.5473L16.9174 6.91825C16.9099 6.81039 16.7899 6.71697 16.67 6.79594L5.42124 14.3032L1.64169 12.4336C0.756876 11.9949 0.794372 10.7652 1.70915 10.3842L20.3449 2.59666C21.2372 2.22267 22.1972 2.99216 21.9647 3.90532L21.9644 3.90539Z" fill="white"/>
</svg>

        </div>
        <div class="wifi-icon card__icon" v-if="card.specialType && card.specialType == 'digital'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 7.54337C23.5554 7.20439 19.1293 3.55533 12.5 3.55533C11.018 3.55533 9.64476 3.74477 8.39991 4.0339L18.6057 14.323L24 7.54337ZM17.4794 15.7388L3.875 2L2.62028 3.27617L4.64562 5.33001C2.53136 6.30708 1.22723 7.36391 1 7.54337L12.4901 21.99L12.5 22L12.5099 21.99L16.363 17.1446L19.643 20.4546L20.8978 19.1884L17.4794 15.7388Z" fill="black" fill-opacity="0.87"/>
          </svg>
        </div>
      </header>
      <div class="card-content">
        <h3 class="card__title" v-html="card.title"></h3>
        <div class="card__previewText" v-html="card.previewText"></div>
      </div>
      <div class="lottie-container" v-if="card.animation" :style="{ 'width': card.animationWidth + 'px'}">
        <lottie-animation
                ref="anim1"
                :animationData="require('@/assets/lottie/' + card.animation + '.json')"
                :loop="true"
              />
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';



export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      setOverlayed: 'setOverlayed',
      pushQueue: 'pushQueue',
      setIterator: 'setIterator',
    }),
    openCard() {
      this.setOverlayed(true);
      this.limitBodyOverflow();
      this.pushQueue(this.card);
    },
    limitBodyOverflow() {
      document.body.classList.add('overflowed');
    }
  },
  mounted() {
    
  }
}
</script>

<style>
  .card {
    min-width: 380px;
    height: 420px;
    border-radius: 40px;
    
    position: relative;
    overflow: hidden;
    box-shadow: -3px 6px 18px 0px rgba(35, 85, 215, 0.25);
  }

  .card__inner {
    width: 100%;
    height: 100%;
    padding: 27px 25px 27px 33px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-header {
    display: flex;
    align-items: center;
  }

  .card__tag {
    margin-right: 10px;
    color: #fff;
    background: linear-gradient(180deg, #FF9F63 0%, #FF7C08 100%), #FF7900;
    border-radius: 45px;
    padding: 0 18px 0;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    height: 29px;
    box-shadow: -6px 12px 18px 0px rgba(255, 160, 102, 0.25), -3px 6px 12px 0px rgba(255, 121, 0, 0.25);  }

  .card__icon {
    margin-right: 10px;
    display: flex;
  }

  .card-content {
    max-width: 300px;
  }

  .card__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 108%;
    /* or 32px */
    color: #001250;
    margin: 10px 0;
  }

  .card__previewText {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    /* dark blue */
    color: #001250
  }

  .full .card-content {
    max-width: 315px;
  }

  .full .card__title {
    margin: 14px 0;
  }

  .dark .card__title, .dark .card__previewText {
    color: #fff;
  }

  .card-soon {
    position: absolute;
    line-height: 33px;
    height: 33px;
    background: #2355CD;
    transform: rotate(30deg);
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    width: 282px;
    right: -60px;
    top: 33px;
    text-align: center;
    z-index: 2;
  }

  .card.soon .card__inner {
    opacity: 0.5;
  }

  .lottie-container {
    margin: 0 auto;
    top: 20px;
    position: relative;
  }

  /*.card.soon {
    pointer-events: none;
    cursor: default;
    touch-action: none;
  }*/

</style>